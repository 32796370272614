$date-picker-help-button-text-color: $primary-text-color !default;

.CalendarDay__selected {
  background: $primary;
  border-color: $primary;
  color: $primary-text-color;
  &:hover, &:active {
    background: $primary;
    border-color: $primary;
    color: $primary-text-color;
  }
}
.CalendarDay__selected_span {
  background: rgba($primary, 0.75);
  border-color: $primary;
  color: $primary-text-color;
  &:hover, &:active {
    background: rgba($primary, 0.75);
    border-color: $primary;
    color: $primary-text-color;
  }
}
.CalendarDay__hovered_span {
  background: rgba($primary, 0.75);
  border-color: $primary;
  color: $primary-text-color;
  &:hover, &:active {
    background: rgba($primary, 0.75);
    border-color: $primary;
    color: $primary-text-color;
  }
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  &::before {
    border-right-color: $primary;
  }
  &:hover {
    &::before {
      border-right-color: rgba($primary, 0.75);
    }
  }
}
.DayPickerKeyboardShortcuts_showSpan {
  color: $date-picker-help-button-text-color;
}
