@import "color";

$active-color: $smithsolar-color;
$date-picker-help-button-text-color: white;

@import "global-style/globalstyle";
@import "src/shopcrate-framework/scss/shopcrate";
@import "footer";
@import "jumbotron";
@import "navbar";

body {
  background-color: white !important;
}

.border-primary {
  border-color: $beukers-color !important;
}
