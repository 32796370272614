.navbar {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  color: $beukers-color !important;
  font-size: 1.3rem !important;
  font-weight: 700;
}

.navbar-nav {
  .nav-item {
    .nav-link {
      font-size: 1.1rem;
    }
  }
}
