.jumbotron-header {
	color: white;
	text-shadow: 0 0 10px black;
	@if variable-exists(jumbotron-header-background-image) {
		background: url($jumbotron-header-background-image) center;
	}
	background-size: cover;

	h1 {
		font-size: 3.0rem;
		font-weight: 400;
		line-height: 1.2;

		@media (min-width: 576px) {
			font-size: 4.5rem;
		}

		b {
			font-weight: 400;
		}

		.small-title {
			font-size: 3.0rem;
		}
	}

	p {
		font-size: 1.25rem;
		font-weight: 400;

		@media (min-width: 576px) {
			font-size: 1.5rem;
		}

		b {
			font-weight: 400;
		}
	}
}

.jumbotron-sm {
	padding-top: 20px;
	padding-bottom: 20px;

	h1 {
		font-weight: 600;
		font-size: 2.5rem;
	}
}

$jumbotron-background-color: #2D3032 !default;

.jumbotron-footer-non-sticky {
	color: white;
	background-color: $jumbotron-background-color;
	margin-bottom: 0;
	padding-top: 30px;
	padding-bottom: 10px;
	width: 100%;
}

.jumbotron-footer {
	@extend .jumbotron-footer-non-sticky;
	position: absolute;
	bottom: 0;
}