.custom-radio.custom-radio-lg {
  .custom-control-label {
    &:before {
      top: 16px;
    }
    &:after {
      top: 16px;
    }
  }
}
