/*!
 * Extension on bootstrap.
 */

$col-width: 8.33333333%;
$col-xxl-min: 1600;
$col-xl-min: 1200;
$col-lg-min: 992;
$col-md-min: 768;
$col-sm-min: 576;

@import "grid";

.btn-group-justified {
	display: flex;
	width: 100%;
	.btn,
	.btn-group {
		flex: 1;
		.btn {
			width: 100%;
		}
		.dropdown-menu {
			left: auto;
		}
	}
}

@media (min-width: #{$col-xxl-min}px) {
	.container-wide, .container-xxl {
		max-width: #{$col-xxl-min}px !important;
	}
}