@mixin be-col() {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}
@mixin be-order($order) {
	-webkit-box-ordinal-group: $order + 1;
	-ms-flex-order: $order;
	order: $order;
}

@mixin be-col-half($number) {
	width: $col-width * $number + $col-width / 2;
	@include be-col();
}
@mixin be-offset-half($number) {
	margin-left: $col-width * $number + $col-width / 2;
}

/* xxl column */
@media (min-width: #{$col-xxl-min}px) {
	.col-xxl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.order-xxl-first {
		@include be-order(-1);
	}
	.order-xxl-last {
		@include be-order(13);
	}
}
@for $i from 0 through 12 {
	$string: $i;
	@if $i == 0 {
		$string: "";
	}

	@media (min-width: #{$col-xxl-min}px) {
		@if $i != 0 {
			.col-xxl-#{$string} {
				width: $col-width * $i;
				@include be-col();
			}
		}
		.order-xxl-#{$string} {
			@include be-order($i);
		}
		.offset-xxl-#{$string} {
			margin-left: $col-width * $i;
		}
	}
}

/* half columns */
@for $i from 0 through 11 {
	$string: $i;
	@if $i == 0 {
		$string: "";
	}

	.col-#{$string}half {
		@include be-col-half($i);
	}
	.offset-#{$string}half {
		@include be-offset-half($i);
	}
	@media (min-width: #{$col-sm-min}px) {
		.col-sm-#{$string}half {
			@include be-col-half($i);
		}
		.offset-sm-#{$string}half {
			@include be-offset-half($i);
		}
	}
	@media (min-width: #{$col-md-min}px) {
		.col-md-#{$string}half {
			@include be-col-half($i);
		}
		.offset-md-#{$string}half {
			@include be-offset-half($i);
		}
	}
	@media (min-width: #{$col-lg-min}px) {
		.col-lg-#{$string}half {
			@include be-col-half($i);
		}
		.offset-lg-#{$i}half {
			@include be-offset-half($i);
		}
	}
	@media (min-width: #{$col-xl-min}px) {
		.col-xl-#{$string}half {
			@include be-col-half($i);
		}
		.offset-xl-#{$string}half {
			@include be-offset-half($i);
		}
	}
	@media (min-width: #{$col-xxl-min}px) {
		.col-xxl-#{$string}half {
			@include be-col-half($i);
		}
		.offset-xxl-#{$string}half {
			@include be-offset-half($i);
		}
	}
}