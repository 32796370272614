.text-highlight {
	color: $active-color;
}
.text-highlight-alt1 {
	color: $active-color-alt1;
}
.text-highlight-alt2 {
	color: $active-color-alt2;
}

.b-highlight {
	@extend .text-highlight;
	font-weight: bold;
}
.b-highlight-alt1 {
	@extend .text-highlight-alt1;
	font-weight: bold;
}
.b-highlight-alt2 {
	@extend .text-highlight-alt2;
	font-weight: bold;
}

.text-required {
	color: red;
}
.b-required {
	@extend .text-required;
	font-weight: bold;
}
