$progressbar-stepped-color: #ffb126 !default;
@if variable-exists(primary) {
  $progressbar-stepped-color: $primary;
}

.progressbar-stepped {
  padding: 0 15px;

  .step {
    padding: 0;
    position: relative;

    .step-title {
      color: #595959;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .step-description {
      color: #999;
      font-size: 14px;
    }
    .step-dot {
      position: absolute;
      width: 30px;
      height: 30px;
      display: block;
      background: $progressbar-stepped-color;
      top: 45px;
      left: 50%;
      margin-top: -15px;
      margin-left: -15px;
      border-radius: 50%;
      &:after {
        content: ' ';
        width: 14px;
        height: 14px;
        background: #ffffff;
        border-radius: 50px;
        position: absolute;
        top: 8px;
        left: 8px;
      }
    }
    .progress {
      position: relative;
      border-radius: 0;
      height: 8px;
      box-shadow: none;
      margin: 17px 0;
      .progress-bar {
        width:0;
        box-shadow: none;
        background: $progressbar-stepped-color;
      }
    }
  }

  .step.complete {
    .progress {
      .progress-bar {
        width: 100%;
      }
    }
  }
  .step.active {
    .progress {
      .progress-bar {
        width: 50%;
      }
    }
  }
  .step.disabled {
    .step-dot {
      background-color: #e0e0e0;
      &:after {
        opacity: 0;
      }
    }
    a.step-dot {
      pointer-events: none;
    }
  }
  .step:first-child {
    .progress {
      left: 50%;
      width: 50%;
    }
    &.active {
      .progress {
        .progress-bar {
          width: 0;
        }
      }
    }
  }
  .step:last-child {
    .progress {
      width: 50%;
    }
    &.active {
      .progress {
        width: 100%;
      }
    }
  }
}
