.call-to-action {
	@extend .text-center;
	margin-top: 100px;
	margin-bottom: 75px;
}

.call-to-action-button {
	@extend .btn;
	@extend .btn-primary;
	@extend .btn-lg;
	margin-top: 1.5rem;
}