/* Home page carousel */
.carousel-home .carousel-caption {
	color: white;
	text-shadow: 0 0 10px black;

	.btn {
		text-shadow: none;

		@media (min-width: 992px) {
			font-size: 35px;
		}
	}

	h1 {
		font-size: 60px;

		@media (max-width: 1200px) {
			font-size: 50px;
		}

		@media (max-width: 991px) {
			font-size: 25px;
		}
	}

	@media (max-width: 991px) {
		h3 {
			font-size: 15px;
		}
	}
}

.carousel-home .carousel-item {
	height: 60vh;
	min-height: 450px;
}

/* Product page carousel */
.carousel-product .product-container-logo {
	margin: 20px 50px;
	@media (min-width: 768px) {
		margin: 50px;
	}

	img {
		@extend .img-fluid;
		max-height: 250px;
	}
}
.carousel-product .product-container-text {
	margin-top: 20px;
	padding: 0 60px;
	@media (min-width: 768px) {
		margin-top: 60px;
		padding: 0;
	}

	text-shadow: 0 0 20px #000;

	p a,
	li a {
		color: lighten($active-color, 10%);
	}

	.btn {
		text-shadow: none;
	}
}
.carousel-product .carousel-item {
	color: white;
	min-height: 600px;

	@media (min-width: 768px) {
		min-height: 350px;
	}
}

/* Global carousel */
.carousel {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover !important;

	background-position: center !important;
	background-repeat: no-repeat !important;
	background-attachment: scroll !important;
}
.carousel-item {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover !important;

	background-position: center !important;
	background-repeat: no-repeat !important;
	background-attachment: scroll !important;
}

.carousel-indicators-circle li {
	@extend .carousel-indicators, li;
	width: 16px;
	height: 16px;
	border: 1px #fff;
	border-radius: 8px;
}
