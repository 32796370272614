.btn-app {
	background-repeat: no-repeat;
	background-position: left top;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	display: block;
	width: 200px;
	height: 60px;
	text-indent: -9999px;
}

.btn-ios {
	background-image: url('https://cdn.jksmithholding.nl/img/assets/btn-app-store.png');
}
.btn-android {
	background-image: url('https://cdn.jksmithholding.nl/img/assets/btn-google-play.png');
}
.btn-android-download {
	background-image: url('https://cdn.jksmithholding.nl/img/assets/btn-android-app.svg');
}